import { Text } from '@chakra-ui/react';
import React from 'react';

import type { Transaction } from 'types/api/transaction';

import type { StatusTagType } from './StatusTag';
import StatusTag from './StatusTag';

export interface Props {
  status: Transaction['status'];
  errorText?: string | null;
  isLoading?: boolean;
}

const TxStatus = ({ status, errorText, isLoading }: Props) => {
  if (status === undefined) {
    return null;
  }

  let text;
  let color;

  switch (status) {
    case 'ok':
      text = 'Success';
      color = 'green.200';
      break;
    case 'error':
      text = 'Failed';
      break;
    case null:
      text = 'Pending';
      break;
  }

  return (
    <Text
      textTransform="uppercase"
      fontSize="sm"
      fontWeight="bold"
      color={color}
    >
      {text}
    </Text>
  );
};

export default TxStatus;
