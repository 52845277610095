import { Box, Text } from '@chakra-ui/react';
import React from 'react';

import type { TxMethodUiConfig } from 'types/ui';

export interface Props {
  methodTypeConfig: TxMethodUiConfig;
}

const TxMethodType = ({ methodTypeConfig }: Props) => {
  return (
    <Box backgroundColor={methodTypeConfig.colorScheme} px={3} rounded="sm">
      <Text fontWeight="medium" color="white">
        {methodTypeConfig.label}
      </Text>
    </Box>
  );
};

export default TxMethodType;
