import type { Transaction } from 'types/api/transaction';
import type { TxMethodUiConfig } from 'types/ui';

export default function getTxMethodTypeConfig(
  tx: Transaction | undefined,
): TxMethodUiConfig | null {
  if (!tx) {
    return null;
  }

  if (tx.method != null) {
    switch (tx.method) {
      // TODO: all decode methods
      case 'decodeNFTCubeForNFTItemWithVL':
        return {
          colorScheme: 'txTypeDecode',
          icon: 'decode',
          label: 'Decode',
        };

      case 'mint':
        return {
          colorScheme: 'txTypeMint',
          icon: 'mint',
          label: 'Mint',
        };

      default:
        return null;
    }
  }

  return null;
}
